<template>
  <LiefengContent>
      <template #title>{{'区运营报表'}}</template>
       <template #toolsbarRight>
          <DatePicker :value="dateTime" format="yyyy-MM-dd" type="date" placeholder="请选择日期" @on-change="changeDate" style="width: 200px"></DatePicker>
          <Select  v-model="selectValue" style="width:200px" @on-change="changeSelect">
            <Option v-for="(item,index) in selectList" :value="item.value" :key="index">{{ item.label }}</Option>
        </Select>
        <Button type="primary" @click="exportHtml">导出报表</Button>
      </template>
      <template #contentArea>
          <!--全区随约平台注册用户统计表-->
          <Card style="margin:20px 0;">
              <TitleColor title='全区随约平台注册用户统计表'>
              </TitleColor>

               <!--全区随约平台注册用户统计表-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing :showLeft="true" :ringData="streeUsage.ring" :listData='streeUsage.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="streeUsage.tableData" :tableColumn="streeUsage.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>


          <!--示范社区注册用户数统计表-->
          <Card style="margin:20px 0;">
              <TitleColor title='示范社区注册用户数统计表'>
              </TitleColor>

               <!--示范社区注册用户数统计表-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing  :showLeft="true" :ringData="orgCodeRegister.ring" :listData='orgCodeRegister.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="orgCodeRegister.tableData" :tableColumn="orgCodeRegister.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>


          <!--非示范社区注册用户数统计表-->
          <Card style="margin:20px 0;">
              <TitleColor title='非示范社区注册用户数统计表'>
              </TitleColor>

               <!--非示范社区注册用户数统计表-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing :showLeft="true" :ringData="noOrgCodeRegister.ring" :listData='noOrgCodeRegister.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="noOrgCodeRegister.tableData" :tableColumn="noOrgCodeRegister.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>


          <!--全区社区动员组织体系建设情况一览表-->
          <Card style="margin:20px 0;">
              <TitleColor title='全区社区动员组织体系建设情况一览表'>
                  <template #titleRight>
                      <span style="margin-right:10px">新增数相对时间:</span>
                     <Select v-model="compareWeek" style="width:200px" @on-change="changeTime">
                        <Option value="1">{{'一周前'}}</Option>
                        <Option value="2">{{'两周前'}}</Option>
                    </Select>
                  </template>
              </TitleColor>

               <!--全区社区动员组织体系建设情况一览表-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing :showLeft="true" :ringData="organizationManager.ring" :listData='organizationManager.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="organizationManager.tableData" :tableColumn="organizationManager.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>


          <!--示范社区“社区组织动员体系”（居民/网上家园）建设情况统计-->
          <Card style="margin:20px 0;">
              <TitleColor title='示范社区“社区组织动员体系”（居民/网上家园）建设情况统计'>
              </TitleColor>

               <!--示范社区“社区组织动员体系”（居民/网上家园）建设情况统计-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing :showLeft="true" :ringData="haveOrganizationManager.ring" :listData='haveOrganizationManager.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="haveOrganizationManager.tableData" :tableColumn="haveOrganizationManager.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>


          <!--非示范社区“社区组织动员体系”（居民/网上家园）入驻情况-->
          <Card style="margin:20px 0;">
              <TitleColor title='非示范社区“社区组织动员体系”（居民/网上家园）入驻情况'>
              </TitleColor>

               <!--示范社区“社区组织动员体系”（居民/网上家园）入驻情况-->
              <div class="stree-div">
                  <div class="left">
                      <VeRing :showLeft="true" :ringData="noOrganizationManager.ring" :listData='noOrganizationManager.list'></VeRing>
                  </div>
                  <div class="right">
                      <VeTable :tableData="noOrganizationManager.tableData" :tableColumn="noOrganizationManager.streeColumn"></VeTable>
                  </div>
              </div>
          </Card>
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2'
import TitleColor from '../components/TitleColor'
import More from '../components/More'
import VeRing from '../components/VeRing'
import Ring from 'v-charts/lib/ring.common'
import VeTable from '../components/Table'
import CardItem from '../components/CardItem'
import VeHistogram from '../components/VeHistogram'
import VeBar from '../components/VeBar'
export default {
    components:{LiefengContent,TitleColor,More,VeRing,Ring,VeTable,CardItem,VeHistogram,VeBar},
    data(){
        return{
            // 存放选择区的数组
            selectList:[],
            selectValue:'',
            dateTime:'',

            // 当前所选区的名称
            codeName:'',

            // 全区随约平台注册用户统计表
            streeUsage:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            // 示范社区注册用户数统计表
            orgCodeRegister:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },

            // 非示范社区注册用户数统计表
            noOrgCodeRegister:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            // 全区社区动员组织体系建设情况一览表
            organizationManager:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            // 示范社区“社区组织动员体系”（居民/网上家园）建设情况统计
            haveOrganizationManager:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            // 非示范社区“社区组织动员体系”（居民/网上家园）入驻情况
            noOrganizationManager:{
                list:{},
                ring:{
                },
                tableData:[],
                streeColumn:[]
            },
            compareWeek:"1"
        }
    },
    async created(){
      this.dateTime = this.$core.formatDate(new Date((new Date()).getTime()-24  *60 * 60 * 1000),'yyyy-MM-dd') 
      await this.getCascader()
      await this.getQueryList(this.selectValue)
      await this.getManagerQueryList(this.selectValue)
    },
    methods:{
        // 方法部分
        // 点击导出报表功能
        exportHtml(){
            let dev = ''
            if(process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'testbuild' || process.env.NODE_ENV == 'dev' ){
                dev = 'test'
            }else{
                dev = 'pord'
            }
            window.open(`https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20220317/463925236.html?dev=${dev}&zoneCode=${this.selectValue}&startDate=${this.dateTime}&token=${window.sessionStorage.getItem('accessToken')}&codeName=${this.codeName}&compareWeek=${this.compareWeek}`)
        },
        // 选择时间事件
        changeDate(val){
            this.dateTime = val
            if(val)  this.getQueryList(this.selectValue);this.getManagerQueryList(this.selectValue)
        },
        // 选择区事件
        changeSelect(val){
            this.selectList.map(item =>{
                if(item.value == val){
                    this.codeName = item.label
                }
            })
            if(val) this.getQueryList(val);this.getManagerQueryList(val)
           
        },
        changeTime(val){
            this.compareWeek = val
            this.getQueryList(this.selectValue);this.getManagerQueryList(this.selectValue)
        },

        // 接口部分
        // 获取quertList接口
        async getQueryList(zoneCode){
           let res = await this.$get('/statistic/api/symanage/pc/orgManageWeekly/queryLists',{
               statDate:this.dateTime,
               zoneCode
           })
           let data = res.data
           if(res && res.code == 200){
               // 全区随约平台注册用户统计表
               if(data.orgManageWeeklyStreetVos && data.orgManageWeeklyStreetVos.length){
                   this.streeUsage.streeColumn = [
                       {
                            title: '街道名称',
                            key: 'streetName',
                            align: "center"
                        },
                       {
                            title: '累计用户数',
                            key: 'totalUser',
                            align: "center",
                        },
                       {
                            title: '近两周新增用户',
                            key: 'twoWeekNew',
                            align: "center",
                        },
                       {
                            title: '示范社区累计注册用户数',
                            key: 'onLineCommunityUser',
                            align: "center",
                        },
                       {
                            title: '非示范社区累计注册用户数',
                            key: 'noOnLineCommunityUser',
                            align: "center",
                        },
                    ]
                   
                    let row = {
                        noOnLineCommunityUser:0,
                        onLineCommunityUser:0,
                        streetName:'小计',
                        totalUser:0,
                        twoWeekNew:0
                    }
                    data.orgManageWeeklyStreetVos.map(item => {
                        row.noOnLineCommunityUser += (item.noOnLineCommunityUser ? item.noOnLineCommunityUser : 0)
                        row.onLineCommunityUser += (item.onLineCommunityUser ? item.onLineCommunityUser : 0)
                        row.totalUser += ( item.totalUser ? item.totalUser : 0)
                        row.twoWeekNew += (item.twoWeekNew ? item.twoWeekNew : 0)
                    })
                    this.streeUsage.tableData = data.orgManageWeeklyStreetVos
                    this.streeUsage.tableData.push(row)
                     this.streeUsage.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'累计用户总数',num:row.totalUser},
                           {title:'示范社区累计注册用户总数',num:row.onLineCommunityUser},
                        //    {title:'街道总数',num:data.total},
                           {title:'非示范社区累计注册用户总数',num:row.noOnLineCommunityUser},
                           {title:'近两周新增用户总数',num:row.twoWeekNew},
                       ],
                    }
                    this.streeUsage.list = {
                        rows:[
                            {title:'累计用户总数',num:row.totalUser},
                            {title:'示范社区累计注册用户总数',num:row.onLineCommunityUser},
                            {title:'非示范社区累计注册用户总数',num:row.noOnLineCommunityUser},
                            {title:'近两周新增用户总数',num:row.twoWeekNew},
                        ],
                    }

               }

               // 示范社区与非示范社区
               if(data.orgManageWeeklyVos && data.orgManageWeeklyVos.length){
                   this.orgCodeRegister.streeColumn = this.noOrgCodeRegister.streeColumn = [
                        {
                            title: '街镇名称',
                            key: 'streetName',
                            align: "center"
                        },
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '近两周新增用户',
                            key: 'twoWeekOnlineHome',
                            align: "center",
                        },
                       {
                            title: '累计注册用户数',
                            key: 'userTotal',
                            align: "center",
                        }
                   ]
                   this.orgCodeRegister.tableData = []
                   this.noOrgCodeRegister.tableData = []
                   let row = {
                        userTotal:0,
                        twoWeekOnlineHome:0
                   }
                   let rows = {
                        userTotal:0,
                        twoWeekOnlineHome:0
                   }
                    data.orgManageWeeklyVos.map(item =>{
                        if(item.isOnline && item.isOnline == '1'){
                            this.orgCodeRegister.tableData.push(item)
                            row.userTotal += item.userTotal ? item.userTotal : 0
                            row.twoWeekOnlineHome += item.twoWeekOnlineHome ? item.twoWeekOnlineHome : 0
                        }else{
                            this.noOrgCodeRegister.tableData.push(item)
                            rows.userTotal += item.userTotal ? item.userTotal : 0
                            rows.twoWeekOnlineHome += item.twoWeekOnlineHome ? item.twoWeekOnlineHome : 0
                        }
                    })
                    this.orgCodeRegister.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'累计注册用户总数',num:row.userTotal},
                           {title:'近两周新增用户总数',num:row.twoWeekOnlineHome},
                       ],
                    }
                     this.orgCodeRegister.list = {
                        rows:[
                            {title:'累计注册用户总数',num:row.userTotal},
                            {title:'近两周新增用户总数',num:row.twoWeekOnlineHome},
                        ],
                    }
                    this.noOrgCodeRegister.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'累计注册用户总数',num:rows.userTotal},
                           {title:'近两周新增用户总数',num:rows.twoWeekOnlineHome},
                       ],
                    }
                     this.noOrgCodeRegister.list = {
                        rows:[
                            {title:'累计注册用户总数',num:rows.userTotal},
                            {title:'近两周新增用户总数',num:rows.twoWeekOnlineHome},
                        ],
                    }
               }

               // 示范社区社区动员体系与非示范社区动员习题
               if(data.orgManageWeeklyVos && data.orgManageWeeklyVos.length){
                   this.haveOrganizationManager.streeColumn = this.noOrganizationManager.streeColumn = [
                        {
                            title: '街镇名称',
                            key: 'streetName',
                            align: "center"
                        },
                       {
                            title: '社区名称',
                            key: 'orgName',
                            align: "center",
                        },
                       {
                            title: '近两周新增用户',
                            key: 'userLately',
                            align: "center",
                        },
                       {
                            title: '累计注册用户数',
                            key: 'onlineHome',
                            align: "center",
                        }
                   ]
                   this.haveOrganizationManager.tableData = []
                   this.noOrganizationManager.tableData = []
                   let row = {
                        onlineHome:0,
                        userLately:0
                   }
                   let rows = {
                        onlineHome:0,
                        userLately:0
                   }
                    data.orgManageWeeklyVos.map(item =>{
                        if(item.isOnline && item.isOnline == '1'){
                            this.haveOrganizationManager.tableData.push(item)
                            row.onlineHome += item.onlineHome ? item.onlineHome : 0
                            row.userLately += item.userLately ? item.userLately : 0
                        }else{
                            this.noOrganizationManager.tableData.push(item)
                            rows.onlineHome += item.onlineHome ? item.onlineHome : 0
                            rows.userLately += item.userLately ? item.userLately : 0
                        }
                    })
                    this.haveOrganizationManager.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'累计注册用户总数',num:row.onlineHome},
                           {title:'近两周新增用户总数',num:row.userLately},
                       ],
                    }
                     this.haveOrganizationManager.list = {
                        rows:[
                            {title:'累计注册用户总数',num:row.onlineHome},
                            {title:'近两周新增用户总数',num:row.userLately},
                        ],
                    }
                    this.noOrganizationManager.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'累计注册用户总数',num:rows.onlineHome},
                           {title:'近两周新增用户总数',num:rows.userLately},
                       ],
                    }
                     this.noOrganizationManager.list = {
                        rows:[
                            {title:'累计注册用户总数',num:rows.onlineHome},
                            {title:'近两周新增用户总数',num:rows.userLately},
                        ],
                    }
               }

              
           }
        },

        // 获取全区社区组织动员体系建设情况一览表
        async getManagerQueryList(zoneCode){
            let res = await this.$get('/statistic/api/symanage/pc/orgManageOverall/queryList',{
                statDate:this.dateTime,
                zoneCode,
                compareWeek:this.compareWeek
            })
             let data = res.data
             this.organizationManager.tableData = []
             this.organizationManager.streeColumn = [
                 {
                     title: '指标',
                     key: 'name',
                     align: "center"
                 },
                {
                     title: '市级示范社区',
                     key: 'cityOnline',
                     align: "center",
                 },
                {
                     title: '区级示范社区',
                     key: 'zoneOnline',
                     align: "center",
                 },
                {
                     title: '非示范社区',
                     key: 'noOnline',
                     align: "center",
                 },
                {
                     title: '新增数',
                     key: 'newOnline',
                     align: "center",
                 },
                {
                     title: '合计（所有社区）',
                     key: 'total',
                     align: "center",
                 },
             ]
             let rows = {
                 baseDataTotal:'',
                 haveHouseTotal:'',
                 haveManyOnlinePeopleTotal:'',
                 onlinePeopleTotal:'',
                 haveWorkGroupTotal:'',
                 onlineFamilyTotal:''
             }
             if(res && res.code == 200){
                 if(data){
                     for(var k in data){
                           if(k == 'baseData'){
                                this.organizationManager.tableData.push({
                                    name:'基本数据',
                                    ...data[k]
                                })
                                rows.baseDataTotal = data[k].total
                            }
                            else if(k == 'haveHouse'){
                                this.organizationManager.tableData.push({
                                    name:'完成组织架构导入的社区',
                                    ...data[k]
                                })
                                rows.haveHouseTotal = data[k].total
                            }
                            else if(k == 'haveManyOnlinePeople'){
                                this.organizationManager.tableData.push({
                                    name:'已启动推广的社区',
                                    ...data[k]
                                })
                                 rows.haveManyOnlinePeopleTotal = data[k].total
                            }
                            else if(k == 'onlinePeople'){
                                this.organizationManager.tableData.push({
                                    name:'入驻人数',
                                    ...data[k]
                                })
                                 rows.onlinePeopleTotal = data[k].total
                            }
                            else if(k == 'haveWorkGroup'){
                                this.organizationManager.tableData.push({
                                    name:'已创建工作群的社区',
                                    ...data[k]
                                })
                                 rows.haveWorkGroupTotal = data[k].total
                            }
                            else if(k == 'onlineFamily'){
                                this.organizationManager.tableData.push({
                                    name:'入驻户数',
                                    ...data[k]
                                })
                                 rows.onlineFamilyTotal = data[k].total
                            }
                     }
                 }
                 this.organizationManager.ring = {
                       columns:['title','num'],
                       rows:[
                           {title:'基本数据总数',num:rows.baseDataTotal},
                           {title:'完成组织架构导入的社区总数',num:rows.haveManyOnlinePeopleTotal},
                           {title:'已启动推广的社区总数',num: rows.haveManyOnlinePeopleTotal},
                           {title:'入驻人数',num:rows.onlinePeopleTotal},
                           {title:'已创建工作群的社区',num:rows.haveWorkGroupTotal},
                           {title:'入驻户数',num:rows.onlineFamilyTotal},
                       ],
                    }
                    this.organizationManager.list = {
                        rows:[
                           {title:'基本数据总数',num:rows.baseDataTotal},
                           {title:'完成组织架构导入的社区总数',num:rows.haveManyOnlinePeopleTotal},
                           {title:'已启动推广的社区总数',num: rows.haveManyOnlinePeopleTotal},
                           {title:'入驻人数',num:rows.onlinePeopleTotal},
                           {title:'已创建工作群的社区',num:rows.haveWorkGroupTotal},
                           {title:'入驻户数',num:rows.onlineFamilyTotal},
                        ],
                    }
         
             }
        },
         // 获取级联
       async getCascader(){
            await this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:'4401',
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                    this.selectList = this.forMatterTree(res.dataList)
                    this.selectValue = this.selectList[0].value
                    this.codeName = this.selectList[0].label
                }
            })
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName;
                data[i].value = data[i].orgCode;
                if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
                    this.forMatterTree(data[i].children);
                }
            }
            return data;
        },
    }
}
</script>


<style lang="less" scoped>
.stree-div{
    display: flex;
    margin-top: 20px;
    .left{
        width: 50%;
    }
    .right{
        margin-left: 20px;
        width: 50%;
        min-height: 200px;
        max-height: 500px;
    }
}
.card-view{
    display: flex;
    height: 120px;
    justify-content: center;
}
.bar-div{
    // min-height: 400px;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px dashed #ccc;
    &:nth-last-child(1){
        border-bottom: none;   
    }
}
.table-view{
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 20px;
}
.user-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 500px;
    .list-div{
        height: 100%;
        width: 45%;
    }
}
</style>